import { Component } from "react";

export default class Refer extends Component {
  componentDidMount() {
    window.location.replace(`${process.env.GATSBY_ACCOUNT_URL}/refer`);
  }

  render() {
    return null;
  }
}
